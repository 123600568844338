import { useEffect, useMemo, useState } from 'react';
import { useTranslate } from '../../customHooks';
import { withRouter } from 'react-router-dom';
import { Table } from '../../components/PANWDSElements';
import { ColumnOptions } from "@panwds/react-table";
import { useGetRulesByRuleStackQuery } from '../../redux/services/rules-service';
import { toast } from '../../components';
import { actionMapping, buidConstratints, flatPropertiesAndArrayValue } from './rulesUtils';

const LocalRulesList = (props: Record<string, any>) => {
  const translate = useTranslate();
  const [nextToken, setNextToken] = useState<string | undefined | null>(null);
  const [localRulesList, setLocalRulesList] = useState<any[]>([]);
  const RuleStackName = props.RuleStackName;
  const Region = props?.Region;
  const { data: localRules, error, isFetching } = useGetRulesByRuleStackQuery({
    RuleListName: "LocalRule", RuleStackName, NextToken: nextToken,
    Describe: true, MaxResults: 50, Region
  }, {
    skip: nextToken === undefined || ! RuleStackName, // Skip the initial query if no nexttoken is provided
  });;

  if (error) {
    toast.error(error?.error, {toastId: "support-describe"});
  }

  useEffect(() => {
    if (localRules && localRules.ruleEntries) {
      setLocalRulesList((prevItems) => [...prevItems, ...localRules.ruleEntries]);
      setNextToken(localRules.nextToken);
    }
  }, [localRules]);

  const columns = useMemo<ColumnOptions<any>[]>(
    () => [
    {
      accessor: 'Priority',
      Header: translate(`resources.rules.fields.Priority`),
    },
    {
      accessor: 'Rule.RuleName',
      Header: translate(`resources.rules.fields.RuleName`),
    },
    {
      accessor: 'Rule.Enabled',
      Header: translate(`resources.rules.fields.Enabled`),
      Cell: ({ value }: { value: string }) => <>{(value) ? translate('generic.yes') : translate('generic.no')}</>,
    },
    {
      accessor: 'Rule.Source',
      Header: translate(`resources.rules.fields.Source`),
      Cell: ({ value }: { value: string }) => <>{flatPropertiesAndArrayValue(value)}</>,
    },
    {
      accessor: 'Rule.Destination',
      Header: translate(`resources.rules.fields.Destination`),
      Cell: ({ value }: { value: string }) => <>{flatPropertiesAndArrayValue(value)}</>,
    },
    {
      accessor: 'Rule.Action',
      Header: translate(`resources.rules.fields.Action`),
      Cell: ({ value }: { value: string }) => <>{actionMapping[value as keyof typeof actionMapping] || value}</>,
    },
    {
      accessor: 'Rule.Constraints',
      Header: translate(`resources.rules.fields.Constraints`),
      Cell: ({ row }: Record<string, any>) => <>{buidConstratints(row?.original)}</>,
    },
    {
      accessor: 'Rule.Logging',
      Header: translate(`resources.rules.fields.Logging`),
      Cell: ({ value }: { value: string }) => <>{(value) ? translate('generic.yes') : translate('generic.no')}</>,
    },
    {
      accessor: 'Rule.DecryptionRuleType',
      Header: translate(`resources.rules.fields.DecryptionRuleType`),
    },
  ],
  []
);

  return (
    <>
      <Table
        tableComponentData={localRulesList || []}
        tableComponentColumns={columns}
        tableComponentEmptyStateProps={{
          heading: translate('resources.rules.fields.EmptyTitle'),
        }}
        tableComponentFilterBar={{
          hideAddFilter: true,
          filterConfig: undefined,
        }}
        tableBatchAction={undefined}
        tableIsLoading={isFetching}
        tableisBackgroundFetching={undefined}
        tableTitle={translate(`resources.rules.name`)}
        tableOffsetHeight={250}
      />
    </>
  );
}

export default withRouter(LocalRulesList);
