import { withRouter } from 'react-router-dom';
import LocalRulesList from './LocalRulesList';
import GlobalRulesList from './GlobalRulesList';


const RulesList = (props: Record<string, any>) => {

  const RulestackName = props?.data?.Firewall?.RuleStackName;
  const GlobalRuleStackname = props?.data?.Firewall?.GlobalRuleStackName;
  const Region = props?.data?.Firewall?.Region;

  return (
    <>
      {GlobalRuleStackname ? <GlobalRulesList Region={Region} RuleStackName={GlobalRuleStackname} /> :
        <LocalRulesList Region={Region}  RuleStackName={RulestackName} />}
    </>
  );
}

export default withRouter(RulesList);
