import { isEmpty } from "lodash";

export const buidConstratints = (data: any) => {
    const constraints = [];
    if (data?.Rule?.Applications.length > 0) {
        constraints.push(`Application: ${data.Rule?.Applications.toString()}`);
    }
    if (!isEmpty(data?.Rule?.Category)) {
        constraints.push(`URL Category: ${Object.values(data.Rule?.Category).join(", ")}`);
    }
    if (data?.Rule?.Protocol) {
        constraints.push(`Protocol: ${data.Rule?.Protocol}`);
    } else if (data?.Rule?.ProtPortList.length > 0) {
        constraints.push(`Protocol: ${data.Rule?.ProtPortList.join(", ")}`);
    }
    return <span title={decodeURI(constraints.join('%0A'))}>{constraints.join(', ')}</span>;
}

export const flatPropertiesAndArrayValue = (object: any, splitter = ', ') => {
    const entries = [];
    for (let entry in object) {
        if (object[entry].length > 0) {
            entries.push(`${entry}: ${object[entry].join(', ')}`)
        }
    }
    return entries.join(splitter);
}

export const actionMapping = {
    DenySilent: "Deny",
    Allow: "Allow",
    DenyResetServer: "Reset Server",
    DenyResetBoth: "Reset Both",
}
