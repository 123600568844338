import { makeStyles } from "@material-ui/styles";
import { useGetFirewallByIdQuery } from "../../../../redux/services/firewalls-service";
import GeneralInfoOverview from "./OverviewComponents/GeneralInfoOverview";
import SecurityPoliciesOverview from "./OverviewComponents/SecurityPoliciesOverview";
import EgressNatOverview from "./OverviewComponents/EgressNatOverview";
import UserIdOverview from "./OverviewComponents/UserIdOverview";
import EndpointsOverview from "./OverviewComponents/EndpointsOverview";
import LogsMetricsOverview from "./OverviewComponents/LogsMetricsOverview";
import { useTranslate } from "../../../../customHooks";
import { toast } from "../../../../components";

interface OverviewFirewallProps {
    fwid: string;
    region: string;
}

const useStyles = makeStyles((theme) => ({
    overviewFirewallContainer: {
        margin: '20px'
    },
    overviewFirewallSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        marginTop: '20px'
    },
    overviewFirewallTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
        color: '#333333',
    },
    title: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        marginBottom: '20px'
    },
    flexContainer: {
        flex: 1,
        marginRight: '20px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    flexRowHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14.4px',
        color: '#707070',
        flex: '1'
    },
    flexRowValues: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#333333',
        textAlign: 'left',
        flex: '1'
    },
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        marginRight: '8px'
    },
    truncatedContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '200px'
    }
}));

const FirewallOverview= (props: {firewallData: Record<string, any>, isLoading: boolean, refreshFirewallData: () => void}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { firewallData, isLoading, refreshFirewallData } = props;
    const firewall = firewallData?.Firewall;
    const status = firewallData?.Status;

    // Determine the managed by value
    let managedBy = "Default Rulestack";
    if (firewall?.RuleStackName) {
        managedBy = "Local Rulestack";
    } else if (firewall?.GlobalRuleStackName) {
        managedBy = "Global Rulestack";
    } else if (firewall?.LinkId) {
        managedBy = firewall.LinkId.includes("Link-SCM") ? "SCM" : "Panorama";
    }

    return (
        <div className={classes.overviewFirewallContainer}>
            <h1 className={classes.overviewFirewallTitle}>{translate(`resources.firewallsV2.overview`)}</h1>
            <div className={classes.overviewFirewallSubContainer}>
                <GeneralInfoOverview firewall={firewall} status={status} styles={classes} isLoading={isLoading} />
                <SecurityPoliciesOverview firewall={firewall} status={status} managedBy={managedBy} styles={classes} isLoading={isLoading} />
            </div>
            <div className={classes.overviewFirewallSubContainer}>
                <EgressNatOverview firewall={firewall} styles={classes} isLoading={isLoading} />
                <UserIdOverview firewall={firewall} styles={classes} isLoading={isLoading} />
            </div>
            <div className={classes.overviewFirewallSubContainer}>
                <EndpointsOverview firewall={firewall} styles={classes} isLoading={isLoading} />
                <LogsMetricsOverview firewall={firewall} styles={classes} isLoading={isLoading} />
            </div>
        </div>
    );
};

export default FirewallOverview;
