import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import Skeleton from '@mui/material/Skeleton';
import { CopyButton } from '@panwds/react-ui';

interface EndpointProps {
    firewall: any;
    styles: any;
    isLoading: boolean;
}

const EndpointsOverview: React.FC<EndpointProps> = ({ firewall, styles, isLoading }) => {
    const translate = useTranslate();

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.endpointManagement.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.vpcEndpointService`)}</strong>
                    <span className={styles.flexRowValues}>
                        {isLoading ? (
                            <Skeleton variant="text" width="200px" height="30px" />
                        ) : (
                            <div className={styles.truncatedContainer}>
                                <span className={styles.truncatedText}>{firewall?.EndpointServiceName ?? '-'}</span>
                                {firewall?.EndpointServiceName && <CopyButton text={firewall?.EndpointServiceName} />}
                            </div>
                        )}
                    </span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.endpointManagement.numberOfEndpoints`)}</strong>
                    <span className={styles.flexRowValues}>
                        {isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.Endpoints?.length ?? 0}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EndpointsOverview;
