import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import Skeleton from '@mui/material/Skeleton';

interface LogMetricsProps {
    firewall: any;
    styles: any;
    isLoading: boolean;
}

const LogsMetricsOverview: React.FC<LogMetricsProps> = ({ firewall, styles, isLoading }) => {
    const translate = useTranslate();
    const logConfig = firewall?.LogConfig;

    const logDestinationType = logConfig?.LogDestinationType || "None";
    const logType = logConfig?.LogType?.join(', ') || "Disabled";
    const policyType = logConfig?.RoleType || "None";

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.logMetrics.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logDestinationType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : logDestinationType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : logType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.policyType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : policyType}</span>
                </div>
            </div>
        </div>
    );
};

export default LogsMetricsOverview;
