import React from "react";
import {
    TableProvider,
    ConnectedTable,
    ConnectedTableLayout,
    ConnectedTableFilter,
    HeaderTitle,
} from "@panwds/react-table";
import { FilterBar, useFilters } from "@panwds/react-filters";
import { TableProps } from "../../types";
import { getFilteredTableData } from "../../utils/utils";
import { makeStyles } from '@material-ui/core';
import { LoadingIcon } from "@panwds/react-ui";

const useStyles = makeStyles((theme) => ({
    paginatedScrollTableHeight: (props:any) => ({
        overflowY: 'auto',
        overflowX: 'auto',
        height: `${props.tableOffsetHeight ? 'calc(100vh - ' + props.tableOffsetHeight + 'px)' : '100vh'}`
    }),
  }));

export const Table = ({ ...props }: TableProps) => {
    const { filters } = useFilters("FilterAndTable");
    const tableData = getFilteredTableData(props, filters);
    const classes = useStyles({tableOffsetHeight: props.tableOffsetHeight});

    return (
        <div data-testid="common-table-component">

            <div className="tw-p-2">
                <TableProvider
                    columns={props.tableComponentColumns}
                    data={tableData}
                    enableColumnSort
                    enableResizeColumns
                    initialState={{
                        groupBy: props?.tableInitialGroupBy || [],
                    }}
                    isLoading={props?.tableIsLoading || false}
                >
                    {props.tableComponentFilterBar?.filterConfig && (
                        <div className="tw-flex tw-items-center">
                            <div>
                                <ConnectedTableFilter />
                            </div>
                            <div>
                                <FilterBar
                                    name="FilterAndTable"
                                    filters={
                                        props?.tableComponentFilterBar?.filterConfig
                                            ? props.tableComponentFilterBar?.filterConfig
                                            : []
                                    }
                                    saveOnUnmount={false}
                                    hideAddFilter={
                                        props?.tableComponentFilterBar?.hideAddFilter || false
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <ConnectedTableLayout
                        dataTestId="table-min-height-with-sidebar"
                        sidecar={<div />}
                        overflow
                        enableHeaderTitle
                        controlLeft={
                            <>
                                {props?.tableTitle && (
                                    <HeaderTitle
                                        title={`${props.tableTitle} (${tableData?.length || 0})`}
                                        subtitle={props?.tableSubtitle || ""}
                                    />
                                )}
                                {props?.tableisBackgroundFetching && (<LoadingIcon className="tw-ml-4 tw-animate-spin" />) }
                                {props?.tableBatchAction?.leftControl &&
                                    props?.tableBatchAction?.leftControl}
                            </>
                        }
                        controlRight={
                            <>
                                {!props.tableComponentFilterBar?.filterConfig && <ConnectedTableFilter />}
                                {props?.tableBatchAction?.rightControl &&
                                    props?.tableBatchAction?.rightControl}
                            </>
                        }
                    >
                        {/* TODO handle the auto height logic for table */}
                        <div className={( props.tableOffsetHeight) ? classes.paginatedScrollTableHeight : ""}>
                            <ConnectedTable

                                emptyStateProps={
                                    props.tableComponentEmptyStateProps &&
                                    props.tableComponentEmptyStateProps
                                }
                                appearance="primary"
                                rowSize={props.tableRowSize || "standard"}
                            />
                        </div>
                    </ConnectedTableLayout>
                </TableProvider>
            </div>
        </div>
    );
};
