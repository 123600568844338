import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API, buildError, prepareHeaders } from '../../api/apis';
import { ListRulesRequest, ListRulesResponse } from '../../interfaces/interfaces';


export const rulesService = createApi({
  reducerPath: 'rulesApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => prepareHeaders(headers),
  }),
  endpoints: (builder) => ({
    getRulesByRuleStack: builder.query({
      query: (request: ListRulesRequest) => API.FETCH_RULES(request),
      transformResponse: (
        response: ListRulesResponse,
        meta,
        request
      ) => {
        if (request?.Describe) {
          return {
            ruleStackName: response.Response.RuleStackName,
            ruleListName: response.Response.RuleListName,
            ruleEntries: response.Response.RuleEntryCandidateDescribe?.map(rule =>
              ({ ...rule, RuleListName: response.Response?.RuleListName })) || [],
            nextToken: response.Response.NextToken || undefined,
          };
        } else {
          return {
            ruleStackName: response.Response.RuleStackName,
            ruleListName: response.Response.RuleListName,
            ruleEntries: response.Response.RuleEntryCandidate || [],
            nextToken: response.Response.NextToken || undefined,
          };
        }
      },
      transformErrorResponse: (response) => {
        // todo: Add data-dog logging here
        return buildError(response, "");
      },
    }),
  }),
});

export const { useGetRulesByRuleStackQuery, useLazyGetRulesByRuleStackQuery } = rulesService;
